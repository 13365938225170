<template>
  <div class="sketchboard">
    <div class="top">
      <div class="level">
        <div class="level-left">
          <div class="subtitle is-3">{{timer}}</div>
        </div>
        <div class="level-right">
          <b-button
            @click="endNow"
            v-if="progress == 100 && !finished"
            type="is-primary"
            class="mr-1">
            End Now
          </b-button>
          <b-button
            @click="undo"
            v-if="started && !finished"
            :disabled="!state.length"
            class="mr-1">
            Undo ({{state.length}})
          </b-button>
          <b-button @click="restart" v-if="started && !submitted" type="is-danger" class="mr-1">
            Restart
          </b-button>
          <b-tooltip
            :label="`Toggle reference (${showReference})`"
            type="is-dark" position="is-bottom">
            <b-button @click="toggleShowReference" class="toggle-ref mr-1" :class="showReference">
              <span class="icon">
                <fa-icon :icon="['far', 'image']" />
              </span>
            </b-button>
          </b-tooltip>
          <b-tooltip
            label="Users drawing this right now"
            type="is-dark" position="is-bottom"
            v-if="loggedIn">
            <span class="user-count">
              <span class="icon is-medium icon-users">
                <fa-icon :icon="['fas', 'users']" />
              </span>
              <span class="count">{{userCount}}</span>
            </span>
          </b-tooltip>
        </div>
      </div>

      <b-progress :value="progress" :type="progress >= 100 ? 'is-success' : ''" />
    </div>

    <div class="container">
      <div
        class="reference"
        v-if="lesson && showReference != 'none'"
        :style="{
          'background-image': `url(${referenceImage})`,
          'z-index': showReference == 'over' ? '1' : '-1',
        }">
      </div>

      <canvas
        ref="canvas"
        @mousedown="onMouseDown"
        @contextmenu="onContextMenu"
        :style="{cursor: cursor}"
        class="sketch"
        hidpi="on"
        resize>
      </canvas>

      <div class="overlay" v-if="finished && !submitted">
        <template v-if="time > 0">
          <p class="title is-1">Good job!</p>
          <template v-if="newUser">
            <p class="mb-4">Sign up to save your sketch and continue learning!</p>
            <form @submit.prevent="submit">
              <b-field
                class="signup-field"
                :message="getError('username')"
                :type="{'is-danger': $v.username.$error}">
                <b-input v-model="username" placeholder="Display name" type="text" />
              </b-field>
              <b-field
                class="signup-field"
                :message="getError('email')"
                :type="{'is-danger': $v.email.$error}">
                <b-input v-model="email" placeholder="Email" type="email" />
              </b-field>
              <b-field
                class="signup-field"
                :message="getError('password')"
                :type="{'is-danger': $v.password.$error}">
                <b-input v-model="password" placeholder="Password" type="password" />
              </b-field>
              <p class="field">
                <small>
                  By signing up you indicate that you agree with our
                  <a href="/terms" target="_blank">Terms</a> and
                  <a href="/privacy" target="_blank">Privacy Policy</a>.
                </small>
              </p>
              <b-button
                native-type="submit"
                @click="submit"
                type="is-primary is-large"
                :loading="submitting">
                Submit
              </b-button>
            </form>
          </template>
          <template v-else>
            <p class="mb-4">Submit your sketch now, or if you wish - try again.</p>
            <b-button @click="submit" type="is-primary is-large" :loading="submitting">
              Submit
            </b-button>
          </template>
        </template>
        <template v-else>
          <p class="title is-1">You're out of time!</p>
          <template v-if="newUser">
            <p class="mb-4">You can sign up and your sketch as is, or try again.</p>
          </template>
          <template v-else>
            <p class="mb-4">You can submit your sketch as is, or try again.</p>
          </template>
          <div>
            <b-button @click="submit" type="is-primary is-large mr-2" :loading="submitting">
              Submit
            </b-button>
            <b-button @click="restart" type="is-danger is-large">Restart</b-button>
          </div>
        </template>
      </div>
      <div class="overlay" v-if="!finished && time == 0">
        <p class="title is-1">Uh-oh, you're out of time!</p>
        <p class="mb-4">It doesn't look like you drew much, you can do better than that!</p>
        <b-button @click="restart" type="is-primary is-large">Try again</b-button>
      </div>
      <div class="overlay" v-if="submitted">
        <p class="title is-1">
          <span class="icon has-text-success">
            <fa-icon :icon="['fas', 'check-circle']" />
          </span>
          {{getRandomSuccessMessage()}}
        </p>
        <router-link :to="continueUrl" class="button is-primary is-large">Continue</router-link>
      </div>

    </div>

    <div class="toolbelt level">
      <div class="level-left">
        <div class="level-item">
          <b-input class="color-picker" v-model="color" type="color" name="color" />
          <b-input v-model="size" type="range" name="size" min="1" max="32" step="1" />
          <div class="size-indicator" :style="{height: size + 'px'}"></div>
        </div>
        <div class="palette level-item">
          <div
            v-for="c in palette"
            :key="'palette-' + c"
            @click="color = c"
            :style="{'background-color': c}"
            class="color">
            </div>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-switch v-model="smooth">Smooth lines</b-switch>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import paper from 'paper';
import { mapGetters } from 'vuex';
import md5 from 'md5';
import { required, minLength, email } from 'vuelidate/lib/validators';
import { getValidationError } from '@/services/util';
import API from '@/services/api';

const TIME_LIMIT = 60 * 5;

const successMessages = [
  'All good!',
  'Looking good!',
  'It\'s a masterpiece!',
  'Great work!',
  'Well done!',
  'Better than Picasso!',
  'Awesome!',
  'Monet\'s got nothing on you!',
  'You must be the next Banksy!',
];

export default {
  name: 'Sketchboard',
  props: {
    lesson: {
      type: Object,
      required: true,
    },
    trial: {
      type: Boolean,
      default: false,
    },
    parent: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      scope: null,
      path: null,
      pathStartTime: null,

      started: false,
      time: TIME_LIMIT,
      timeSpent: 0,
      interval: null,
      state: [],
      uuid: null,
      finished: false,
      submitting: false,
      submitted: false,

      color: '#000000',
      size: 5,
      palette: [],
      smooth: true,
      showReference: 'under',
      userCount: 0,

      pngData: null,
      svgData: null,

      newUser: false,
      registered: false,
      username: '',
      email: '',
      password: '',
    };
  },
  validations: {
    username: {
      required,
      minLength: minLength(4),
    },
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(8),
    },
  },
  created() {
    // window.sketchboard = this;
    if (this.user && this.user.favouriteColour) this.color = `#${this.user.favouriteColour}`;
    if (this.lesson) this.time = this.lesson.timeLimit;
    this.$socket.client.emit('sketchOpen', { lessonId: this.lessonId });
    if (!this.loggedIn) this.newUser = true;
  },
  mounted() {
    this.scope = new paper.PaperScope();
    this.scope.setup(this.$refs.canvas);
  },
  beforeDestroy() {
    this.$socket.client.emit('sketchClose', { lessonId: this.lessonId });
    this.scope.remove();
    this.scope.clear();
    this.scope = null;
  },
  watch: {
    state(val) {
      if (val.length > 5) this.state.shift();
    },
    time(val) {
      if (val <= 0) {
        if (this.progress < 100) {
          clearInterval(this.interval);
          this.interval = null;
          this.state = [];
          this.$store.dispatch('site/preventNavigation', false);
        } else {
          this.endNow();
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      loggedIn: 'session/loggedIn',
      user: 'session/user',
    }),
    lessonId() {
      return this.lesson ? this.lesson.id : null;
    },
    continueUrl() {
      return this.lesson ? `/skills/${this.lesson.skillId}` : '/';
    },
    progress() {
      const secs = Math.round(this.timeSpent / 1000);
      const limit = this.lesson ? this.lesson.timeLimit : TIME_LIMIT;
      // 10% of time limit
      return Math.min((secs / (limit / 10) * 100), 100); // eslint-disable-line no-mixed-operators
    },
    cursor() {
      const hasOutline = this.size <= 8;

      const c = document.createElement('canvas');
      c.width = 32;
      c.height = 32;

      const ctx = c.getContext('2d');
      ctx.beginPath();
      ctx.arc(16, 16, this.size / 2, 0, 2 * Math.PI, false);
      ctx.fillStyle = this.color;
      ctx.fill();
      ctx.strokeStyle = '#888';
      ctx.lineWidth = 2;
      if (hasOutline) {
        ctx.beginPath();
        ctx.arc(16, 16, 16, 0, 2 * Math.PI, false);
      }
      ctx.stroke();
      const img = c.toDataURL('image/png');
      // TODO: investigate firefox issues with higher devicePixelRatio
      return `url(${img}) 16 16,url('../images/cursor-pencil.png'),auto`;
    },
    timer() {
      return new Date(this.time * 1000).toISOString().substr(11, 8);
    },
    referenceImage() {
      if (this.parent) return this.parent.imageUrl;
      if (this.lesson) return this.lesson.reference;
      return '';
    },
  },
  methods: {
    toggleShowReference() {
      let val = 'under';
      switch (this.showReference) {
        case 'under':
          val = 'over';
          break;
        case 'over':
          val = 'none';
          break;
        case 'none':
        default:
          val = 'under';
          break;
      }
      this.showReference = val;
    },
    pathCreate(scope) {
      scope.activate();
      return new paper.Path({
        strokeJoin: 'round',
        strokeColor: this.color,
        strokeWidth: this.size,
        strokeCap: 'round',
      });
    },
    createTool(scope) {
      scope.activate();
      return new paper.Tool();
    },
    onContextMenu(e) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    },
    onMouseDown() {
      const self = this;

      this.tool = this.createTool(this.scope);

      this.tool.onMouseDown = (event) => {
        self.prePathStart();
        if (self.path) self.path.selected = false;
        self.pathStartTime = Date.now();
        self.path = self.pathCreate(self.scope);
        self.path.add(event.point);
      };

      this.tool.onMouseDrag = (event) => {
        if (self.path) self.path.add(event);
      };

      this.tool.onMouseUp = () => {
        if (self.smooth && self.path) self.path.simplify(10);
        self.onPathEnd();
      };
    },
    prePathStart() {
      this.state.push(this.scope.project.exportJSON());
      if (!this.started) {
        this.$socket.client.emit('sketchStart', { lessonId: this.lessonId });
        this.started = true;
        this.interval = setInterval(() => {
          if (this.time > 0) this.time -= 1;
        }, 1000);
        this.$store.dispatch(
          'site/preventNavigation',
          'You\'re working on a sketch! Are you sure you want to leave?',
        );
      }
      this.addColorToPalette();
    },
    onPathEnd() {
      this.timeSpent += Date.now() - this.pathStartTime;
      this.pathStartTime = null;
    },
    undo() {
      if (!this.state.length) return;
      const last = this.state.pop();
      this.scope.activate();
      this.scope.project.clear();
      this.scope.project.importJSON(last);
    },
    endNow() {
      if (this.progress < 100) return;
      this.prepareData();
      this.$socket.client.emit('sketchEnd', {
        uuid: this.uuid,
        png: md5(this.pngData),
        svg: md5(this.svgData),
        lessonId: this.lessonId,
      });
      this.finished = true;
      clearInterval(this.interval);
      this.$store.dispatch(
        'site/preventNavigation',
        'You have not submitted your sketch! Are you sure you want to leave?',
      );
    },
    async submit() {
      if (this.submitting || this.submitted || !this.finished) return;

      if (this.newUser) { // signup validation
        this.$v.$touch();
        if (this.$v.$invalid) return;
      }

      this.submitting = true;

      const data = {
        image: this.pngData,
        svg: this.svgData,
        timeSpent: Math.round(this.timeSpent / 1000),
        lessonId: this.lessonId,
      };

      // register if new user
      if (this.newUser) {
        try {
          const res = await this.register();
          data.userId = res.data.user.id;
          data.token = res.data.user.token;
          this.registered = true;
          this.$gtag.event('sign_up');
        } catch (e) {
          const error = API.handleError(e);
          if (error) this.$toasted.error(error.message, { duration: 2000 });
          this.submitting = false;
          return;
        }
      } else {
        data.uuid = this.uuid;
        if (this.parent) data.parentId = this.parent.id;
      }

      try {
        if (this.newUser) {
          await API.sketches.saveTrial(data);
        } else {
          await API.sketches.save(data);
        }
        this.submitted = true;
        this.$store.dispatch('site/preventNavigation', false);
        this.$store.dispatch('session/getProfile');
        if (this.newUser) {
          this.$toasted.success(
            'Your first sketch has been saved, welcome to SketchDaily!',
            { duration: 2000 },
          );
        } else {
          this.$toasted.success('Sketch saved!', { duration: 2000 });
        }
      } catch (e) {
        const error = API.handleError(e);
        if (this.newUser && this.registered) {
          this.$toasted.warn(
            'Registered successfully, but we could not save your sketch this time!',
            { duration: 2000 },
          );
          this.$store.dispatch('session/getProfile');
        }
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }

      this.submitting = false;
    },
    register() {
      const data = {
        username: this.username,
        email: this.email,
        password: this.password,
      };
      return API.auth.signup(data);
    },
    restart() {
      if (this.progress < 100) {
        const ok = window.confirm('Are you sure you want to start from scratch?'); // eslint-disable-line no-alert
        if (!ok) return;
      }
      this.$socket.client.emit('sketchCancel', {
        uuid: this.uuid,
        lessonId: this.lessonId,
      });
      this.scope.activate();
      this.scope.project.clear();
      this.path = null;
      this.pathStartTime = null;
      this.started = false;
      this.time = this.lesson ? this.lesson.timeLimit : TIME_LIMIT;
      this.timeSpent = 0;
      clearInterval(this.interval);
      this.interval = null;
      this.state = [];
      this.palette = [];
      this.finished = false;
      this.$store.dispatch('site/preventNavigation', false);
    },
    prepareData() {
      this.pngData = this.$refs.canvas.toDataURL('image/png').replace('data:image/png;base64,', '');
      this.svgData = this.scope.project.exportSVG().outerHTML;
    },
    addColorToPalette() {
      const idx = this.palette.indexOf(this.color);
      if (idx === -1) {
        this.palette.unshift(this.color);
      } else {
        this.palette.splice(idx, 1);
        this.palette.unshift(this.color);
      }
      if (this.palette.length > 10) this.palette.pop();
    },
    getRandomSuccessMessage() {
      return successMessages[Math.floor(Math.random() * successMessages.length)];
    },
    getError(field) {
      if (!this.$v[field]) return '';
      if (!this.$v[field].$dirty) return '';
      return getValidationError(this.$v[field]);
    },
  },
  sockets: {
    sketchStart(data) {
      if (data.status !== 'ok') {
        console.error('sketchStart error', data);
        return;
      }
      this.uuid = data.uuid;
    },
    sketchCancel(msg) {
      console.log('sketchCancel', msg);
    },
    sketchEnd(msg) {
      console.log('sketchEnd', msg);
    },
    roomUserCount(msg) {
      const count = parseInt(msg, 10);
      if (!Number.isNaN(count)) this.userCount = count;
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  .level {
    margin-bottom: 0.25em;
  }
  .toggle-ref .icon {
    font-size: 1.5em;
  }
  .user-count .count {
    position: relative;
    bottom: 2px;
  }
}
.sketch {
  border: 1px solid #999;
}
.reference {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.3;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  pointer-events: none;
  z-index: -1;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.9);
  z-index: 5;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .icon {
    position: relative;
    top: 6px;
    margin-right: 0.25em;
  }
}
.toolbelt {
  .color-picker {
    min-width: 4em;
  }
  .size-indicator {
    width: 3em;
    background: black;
    margin-left: 0.5em;
  }
  .palette .color {
    display: inline-block;
    width: 2em;
    height: 2em;
    margin-right: 0.25em;
    border: 1px solid #ccc;
    cursor: pointer;
  }
}
.signup-field {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
</style>
